import React from "react"
import { Helmet } from "react-helmet"

// Components
import Layout from "../components/Layout"
import UserForm from "../components/Warranty"
import SEO from "../components/SEO"

// Data
import content from "../pages/static-pages/warranty.yaml"

class Index extends React.Component {
  state = { sliderOptions: "" }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      var win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        x = win.innerWidth || docElem.clientWidth || body.clientWidth

      if (x >= 640) {
        this.setState({ sliderOptions: "draggable: false; sets: true;" })
      } else {
        this.setState({ sliderOptions: "center: true;" })
      }
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        <Helmet defer={false}>
          <meta name="robots" content={content.robots} />
        </Helmet>

        {/* Feature */}
        <div
          className="feature-banner uk-section uk-background-cover uk-light"
          data-src={content.feature.image.url}
          uk-img=""
        >
          <div className="uk-container uk-margin-small-top">
            <div className="uk-width-4-5 uk-width-3-5@s uk-width-1-3@m">
              <h1
                className="uk-margin-small-top uk-text-global-family uk-heading-large"
                dangerouslySetInnerHTML={{ __html: content.feature.title }}
              ></h1>
            </div>
          </div>
        </div>

        {/* Section */}
        <div className="uk-section uk-section-default uk-padding-remove-bottom uk-section-small">
          <div className="uk-container">
            <div className="uk-width-3-5@m uk-width-4-5@s uk-margin-auto uk-text-center">
              <h2 className="uk-text-global-family uk-h2">
                Thank you for purchasing a Kaden
                <br /> heating or cooling product.
              </h2>
              <p className="uk-text-emphasis">
                Kaden is committed to total customer care. You will enjoy
                market-leading warranties and full after-sales support for many
                years to come.
                <br />
                Please complete your details below to register your product and
                unlock your 7-year warranty.
              </p>
            </div>
          </div>
        </div>

        {/* Steps & Form */}
        <div className="uk-section uk-section-default uk-padding-remove-top">
          <div className="uk-container">
            {/* Form */}
            <UserForm />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
